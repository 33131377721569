import React, {useState} from 'react';
import axios from "axios";
import "./successorerror.css"
import {closeSnackbar, useSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
const OrdersTable = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const action = snackbarId => (
        <button onClick={() => { closeSnackbar(snackbarId) }}>
            <CloseIcon/>
        </button>
    );


    (function() {
        function decimalAdjust(type, value, exp) {
            if (typeof exp === 'undefined' || +exp === 0) {
                return Math[type](value);
            }
            value = +value;
            exp = +exp;
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
                return NaN;
            }
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
        }

        if (!Math.floor10) {
            Math.floor10 = function(value, exp) {
                return decimalAdjust('floor', value, exp);
            };
        }
    })();

    const userToken = localStorage.getItem('token');
    const [TP, setTP] = useState(props.takeProfit)
    const [SL, setSL] = useState(props.stopLoss)
    const ChangeSL = () => {
        axios({
            method: "post",
            url: "https://api.dune-ex.net/orders/api/order/change/stop_loss",
            headers: { "Authorization" : `Bearer ${userToken}` },
            data: { "id" : `${props.id}`, "value": SL },
        })
            .then((res) => {
                document.getElementById(`sledit2${props.id}`).classList.add("hidden")
                document.getElementById(`sledit${props.id}`).classList.remove("hidden")
                setSL("")
                props.requireUserData();
            })
    }
    const ChangeTP = () => {
        axios({
            method: "post",
            url: "https://api.dune-ex.net/orders/api/order/change/take_profit",
            headers: { "Authorization" : `Bearer ${userToken}` },
            data: { "id" : `${props.id}`, "value": TP },
        })
            .then((res) => {
                document.getElementById(`tpedit2${props.id}`).classList.add("hidden")
                document.getElementById(`tpedit${props.id}`).classList.remove("hidden")
                setSL("")
                props.requireUserData();
            })
    }

    const DeleteOrder = (event) => {
        event.preventDefault();
        const deleteButton = document.getElementById(`deleteButton${props.id}`)
        deleteButton.setAttribute("disabled", "true")
        document.querySelectorAll('.cancel__buttonDEL').forEach(button => {
            button.disabled = true; // Устанавливаем атрибут disabled
        });
        axios({
            method: "post",
            url: "https://api.dune-ex.net/orders/api/order/change/pnl",
            headers: { "Authorization" : `Bearer ${userToken}` },
            data: { "id" : `${props.id}`, "pnl": props.pnl, "pnl_percent": props.pnlPercent },
        })
            .then(() => {
                axios({
                    method: "post",
                    url: "https://api.dune-ex.net/orders/api/order/close",
                    headers: { "Authorization" : `Bearer ${userToken}` },
                    data: { "id" : `${props.id}`, "pnl": props.pnl, "pnl_percent": props.pnlPercent, "margin": props.margin },
                })
                    .then(res => {
                        props.requireUserData();
                        props.requireUserBalance();
                        setTimeout(() => deleteButton.removeAttribute("disabled"), 500)
                        setTimeout(() => document.querySelectorAll('.cancel__buttonDEL').forEach(button => {
                            button.disabled = false; // Устанавливаем атрибут disabled
                        }), 500)
                        enqueueSnackbar('Ордер успешно закрыт', {action, 'variant': 'success', 'className': "snacksuccess"});
                    })
                    .catch(error => {
                        enqueueSnackbar('Возникла ошибка', {action, 'variant': 'error', 'className': "snackerror"});
                        setTimeout(() => deleteButton.removeAttribute("disabled"), 500)
                        setTimeout(() => document.querySelectorAll('.cancel__buttonDEL').forEach(button => {
                            button.disabled = false; // Устанавливаем атрибут disabled
                        }), 500)

                    })
            })

    }

    const DeleteOrderWait = (event) => {
        event.preventDefault();
        const deleteButton = document.getElementById(`deleteButtonW${props.id}`)
        const buttons = document.querySelectorAll('.cancel__buttonDEL');
        console.log("______"); // Должен вывести NodeList с элементами
        console.log(buttons); // Должен вывести NodeList с элементами
        console.log("_____"); // Должен вывести NodeList с элементами
        document.querySelectorAll('.cancel__buttonDEL').forEach(button => {
            button.disabled = true; // Устанавливаем атрибут disabled
        });
        deleteButton.setAttribute("disabled", "true")
        axios({
            method: "delete",
            url: "https://api.dune-ex.net/orders/api/order",
            headers: { "Authorization" : `Bearer ${userToken}` },
            data: { "id" : `${props.id}`, "pnl": props.pnl, "margin": props.margin },
        })
            .then(res => {
                props.requireUserData();
                props.requireUserBalance();
                setTimeout(() => deleteButton.removeAttribute("disabled"), 500)
                setTimeout(() => document.querySelectorAll('.cancel__buttonDEL').forEach(button => {
                    button.disabled = false; // Устанавливаем атрибут disabled
                }), 500)
                enqueueSnackbar('Ордер успешно закрыт', {action, 'variant': 'success', 'className': "snacksuccess"});
            })
            .catch(error => {
                setTimeout(() => deleteButton.removeAttribute("disabled"), 500)
                setTimeout(() => document.querySelectorAll('.cancel__buttonDEL').forEach(button => {
                    button.disabled = false; // Устанавливаем атрибут disabled
                }), 500)
                enqueueSnackbar('Возникла ошибка', {action, 'variant': 'error', 'className': "snackerror"});
            })
    }

    let leverageDecimal = 100 / props.leverage
    let likvidationProm = props.price / 100 * leverageDecimal
    let likvidationLong = props.price - likvidationProm
    let likvidationShort = props.price + likvidationProm

        return(
    <div className="order-table__item">
        <div className={`bin__orders-line-name bin__orders-line-name-cr ${props.isLong ? "long-ord" : "short-ord"}`}>
            <a href={`/market?symbol=${props.coin}${props.coinfrom.toUpperCase()}`} className="mb0">{props.coin}/{props.coinfrom.toUpperCase()}</a>
            <span>{props.leverage}x</span>
        </div>
        <div className="order-table__children">
            <div className="bin__orders-line-cena">{Math.floor10(props.size, -5)}</div>
        </div>
        <div className="order-table__children">{props.price}</div>
        <div className="order-table__children">{ props.chlen ? Math.floor10(props.marzha, -5) : Math.floor10(props.margin, -5)} {props.coinfrom.toUpperCase()}</div>
        <div className="order-table__children">{ props.isLong ? Math.floor10(likvidationLong, -5) : Math.floor10(likvidationShort, -5)}</div>

        <div className={`order-table__children ${  (props.pnl > 0) ? "success" : "error"}`}>
            {props.pnl} {props.coinfrom.toUpperCase()} <br />
            ({props.pnlPercent}%)
        </div>



        <div className="order-table__children tpsledit" id={`tpedit${props.id}`}>{props.takeProfit} { props.tpslEdit ?<EditOutlinedIcon
            onClick={() => {
                document.getElementById(`tpedit2${props.id}`).classList.remove("hidden");
                document.getElementById(`tpedit${props.id}`).classList.add("hidden")
            }} className={"editIcon"}/> : ""}</div>
        <div className="order-table__children tpsledit2 hidden" id={`tpedit2${props.id}`}><input defaultValue={props.takeProfit} className="order-table__children__input" value={TP} onChange={e => setTP(e.target.value)} placeholder={props.takeProfit} />  <DoneOutlinedIcon onClick={() => ChangeTP()} className={"doneIcon"}/></div>




        <div className="order-table__children tpsledit" id={`sledit${props.id}`}>{props.stopLoss}    { props.tpslEdit ?  <EditOutlinedIcon onClick={() => {document.getElementById(`sledit2${props.id}`).classList.remove("hidden"); document.getElementById(`sledit${props.id}`).classList.add("hidden")}} className={"editIcon"}/> : "" }</div>
        <div className="order-table__children tpsledit2 hidden" id={`sledit2${props.id}`}><input defaultValue={props.stopLoss} className="order-table__children__input" value={SL} onChange={e => setSL(e.target.value)} placeholder={props.stopLoss} />  <DoneOutlinedIcon onClick={() => ChangeSL()} className={"doneIcon"}/></div>




        { props.closeButton ?  <div className="bin__orders-name">
            <button className={`cancel__buttonDEL ${props.deleteButton}`} id={`deleteButton${props.id}`} onClick={DeleteOrder}>Закрыть</button>
        </div> : ""}
        { props.deleteButton ?  <div className="bin__orders-name">
            <button className={`cancel__buttonDEL ${props.deleteButton}`} id={`deleteButtonW${props.id}`} onClick={DeleteOrderWait}>Удалить</button>
        </div> : ""}

    </div>

    );
};

export default OrdersTable;








